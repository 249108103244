<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/manage/paper/list/${$route.query.paperBankId}` }">试卷列表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/manage/paper/detail?id=${$route.query.paperId}` }">试卷详情</el-breadcrumb-item>
        <el-breadcrumb-item>修改试卷题目</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div></div>
      <div class="yt-header-toolbar">
        <el-button type="primary" v-if="isRandom" v-loading="saveLoading" size="small" @click="savePaper" style="width: 80px">
          <div>
            <YTIcon :href="'#icon-baocun'" />
            <p>保存</p>
          </div>
        </el-button>
        <el-button size="small" @click="movePaper" style="width: 80px" v-if="filters.selection.radio === '1' && isRandom">
          <div>
            <YTIcon :href="'#icon-yichu'" />
            <p>移除</p>
          </div>
        </el-button>
        <el-button size="small" @click="goBack" style="width:136px">
          <div>
            <YTIcon :href="'#icon-fanhui'" />
            <p>返回试卷</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="yt-container yt-container-flex" :class="{ 'is-pack-up': isPackUps }">
      <div class="yt-content-tree">
        <template v-if="isPackUps">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUps = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUps = true" />
            <YTIcon style="margin: 0 5px 0 19px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>技能列表</p>
          </div>
          <el-input v-model="keywords" class="yt-search search" placeholder="通过名称筛选" @keydown.enter.native="toFilterNodes('abilty-tree')">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNodes('abilty-tree')"></i>
          </el-input>
          <div class="tree has-tool" style="height: calc(100% - 116px)">
            <el-tree
              :data="treeData"
              :filter-node-method="filterNode"
              node-key="id"
              ref="abilty-tree"
              :props="{ label: 'name', children: 'abilityTreeVOS' }"
              :highlight-current="true"
              :default-expanded-keys="defaultShowNodes"
              @node-click="handleClickTreeNode"
            >
              <div class="tree-node" slot-scope="{ node, data }">
                <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" placement="top-start">
                  <div class="el-tree-node__label">{{ node.label }}</div>
                </el-tooltip>
                <div style="margin-right: 14px" :class="data.paperQuestionCount > 0 ? 'fontColor' : ''">{{ data.paperQuestionCount }}</div>
                <div style="margin-right: 13px">/</div>
                <div style="margin-right: 12px">{{ data.questionCount }}</div>
              </div>
            </el-tree>
          </div>
        </template>
      </div>
      <div class="yt-content">
        <div class="yt-content-top">
          <div class="question-order">
            <el-input v-model="papernew.paperName"></el-input>
          </div>
          <div class="question-news">
            <span>
              <YTIcon style="margin: 0 3px 0 5px;" :href="'#icon-shitixiangqing'"></YTIcon>
              试卷总分：{{ papernew.totalPoints }}分
            </span>
            <span>
              <YTIcon style="margin: 0 3px 0 5px;" :href="'#icon-fenlei'"></YTIcon>
              已选试题：{{ selectedQuestionIds.length }}题
            </span>
          </div>
        </div>
        <!--筛选-->
        <div
          class="filter-container"
          :style="{
            height: filters.selection.radio === '2' ? (isPackUp ? '150px' : '50px') : '50px',
            overflow: 'hidden'
          }"
        >
          <!--搜索框-->
          <div class="input-container" v-if="filters.selection.radio === '2'">
            <el-input v-model="keyword" placeholder="按题目题干关键字搜索" @keydown.enter.native="searchPaperNotSelectedQuestion">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="searchPaperNotSelectedQuestion" />
            </el-input>
          </div>
          <div v-show="filters.selection.radio === '2'" class="pack-up" @click="isPackUp = !isPackUp">
            <p>{{ !isPackUp ? '展开' : '收起' }}</p>
            <YTIcon :class="['icon', isPackUp ? 'active' : '']" :href="'#icon-open'"></YTIcon>
          </div>
          <template v-for="(filter, key, index) in filters">
            <div :key="index" class="row">
              <div class="label">{{ filter.label }}:</div>
              <template v-if="key !== 'other'">
                <template v-if="key === 'selection'">
                  <el-radio-group v-model="filter.radio" @change="agreeChange">
                    <el-radio label="1"><span class="fontSize">已选用</span></el-radio>
                    <el-radio label="2"><span class="fontSize">未选用</span></el-radio>
                  </el-radio-group>
                </template>
                <template v-else>
                  <div
                    v-for="(child, fIndex) in filter.children"
                    :key="fIndex"
                    :class="[
                      'item default-item',
                      {
                        active: searchQuestionForm.searchForm[key] === child.value
                      }
                    ]"
                    @click="handleClickFilter(key, child.value)"
                  >
                    {{ child.label }}
                  </div>
                </template>
                <template v-if="index === 2">
                  <div class="checkbox-container">
                    <el-checkbox v-model="searchQuestionForm.searchForm.haveKnowledge" @change="reSearchQuestions">已绑定知识点</el-checkbox>
                    <el-checkbox v-model="searchQuestionForm.searchForm.hasKnowledge" @change="reSearchQuestions">未绑定知识点</el-checkbox>
                    <el-checkbox
                      v-if="searchQuestionForm.searchForm.questionType === '5'"
                      v-model="searchQuestionForm.searchForm.hasTestCase"
                      @change="reSearchQuestions"
                      >无测试用例</el-checkbox
                    >
                  </div>
                </template>
              </template>
              <template v-else>
                <div v-for="(child, fIndex) in filter.children" :key="fIndex" class="item other-item">
                  <!--排序-->
                  <template v-if="child.type === 'sort'">
                    <div @click="child.function">
                      {{ child.label }}
                      <YTIcon
                        :href="
                          searchQuestionForm.searchForm.useCountDesc === ''
                            ? '#icon-moren'
                            : searchQuestionForm.searchForm.useCountDesc
                            ? '#icon-jiangxu'
                            : '#icon-shengxu'
                        "
                      />
                    </div>
                  </template>
                  <!--下拉菜单-->
                  <template v-else-if="child.type === 'singleSelect'">
                    <el-dropdown @command="child.function" placement="bottom-start">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(option, oIndex) in child.options"
                          :key="oIndex"
                          :command="option.value"
                          :class="{
                            active: searchQuestionForm.searchForm[child.property] === option.value
                          }"
                        >
                          {{ option.label }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <!--多选下拉菜单-->
                  <template v-else-if="child.type === 'multipleSelect'">
                    <el-dropdown @command="child.function" placement="bottom-start" :hide-on-click="false">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown" style="max-height: calc(100vh - 400px);overflow-y: auto">
                        <el-dropdown-item
                          v-for="(option, oIndex) in child.options"
                          :key="oIndex"
                          :command="option.value"
                          :class="{
                            active: searchQuestionForm.searchForm[child.property].includes(option.value)
                          }"
                        >
                          {{ option.label }}
                          <i
                            class="el-icon-check"
                            style="margin-left: 5px"
                            :style="{
                              opacity: searchQuestionForm.searchForm[child.property].includes(option.value) ? 1 : 0
                            }"
                          ></i>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <template v-else-if="child.type === 'tree'">
                    <el-dropdown placement="bottom-start">
                      <span class="select">{{ child.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-input
                          v-model="knowledgePointDownKeyword"
                          class="yt-search search"
                          placeholder="通过知识点名称筛选"
                          @keydown.enter.native="toFilterNode('knowledge-tree-down')"
                        >
                          <i
                            slot="suffix"
                            class="el-input__icon el-icon-search"
                            @click="toFilterNodeDown('knowledge-tree-down', knowledgePointDownKeyword)"
                          ></i>
                        </el-input>
                        <div class="list-container">
                          <el-tree
                            :data="knowledgePointsDown"
                            :props="knowledgePointProps"
                            node-key="value"
                            ref="knowledge-tree-down"
                            :filter-node-method="filterNode"
                            show-checkbox
                            check-strictly
                          >
                          </el-tree>
                        </div>
                        <div class="footer">
                          <el-button type="primary" @click="toSearchByKnowledge">确定</el-button>
                          <el-button size="small" @click="resetCheckedNodesDown('knowledge-tree-down')">
                            <div>
                              <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                              <p>重置</p>
                            </div>
                          </el-button>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
        <!--全选-->
        <div class="select-all" v-if="filters.selection.radio === '1'">
          <el-checkbox
            v-if="this.selectedQuestionIds.length > 0"
            :indeterminate="isIndeterminate"
            v-model="isSelectAll"
            @change="handleCheckAllChange"
            >全选题目</el-checkbox
          >
        </div>
        <!--右侧导航关闭-->
        <div class="fixed-icon" @click="drawer = true" v-if="!drawer && filters.selection.radio === '1'">
          <div class="fixed-icon-num" v-if="questionAllid.length > 0">{{ questionAllid.length }}</div>
          <YTIcon class="fixed-icon-wenjian" :href="'#icon-wenjianjia'" @click="drawer = true" />
        </div>
        <!--题目列表-->
        <div
          class="question-container"
          :style="{
            height: filters.selection.radio === '2' ? (isPackUp ? 'calc(100% - 235px)' : 'calc(100% - 135px)') : 'calc(100% - 174px)'
          }"
          v-loading="questionListLoading"
          element-loading-text="加载中"
        >
          <div
            class="list"
            :style="{
              height: questionListLoading ? '100%' : 'auto',
              overflowY: questionListLoading ? 'hidden' : 'auto'
            }"
          >
            <!--题目卡片-->
            <template v-if="filters.selection.radio === '1'">
              <div>
                <template>
                  <div v-for="(item, index) in questionList" v-if="item.questionCount !== 0">
                    <div class="question-sort">
                      <p>{{ item.name }}</p>
                      <p style="color: #448BFF">{{ item.questionCount }}</p>
                      <p>题</p>
                      <el-input
                        maxLength="3"
                        style="margin-right: 5px"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        v-model="item.totalScore"
                        @keydown.enter.native="setBigTopicPoint(item.totalScore, index)"
                      ></el-input>
                      <p>分</p>
                    </div>
                    <div v-for="(question, index) in questionList[index].questionScores" :key="index" class="item" :id="'anchor-' + question.id">
                      <template v-for="item in questionAll" v-if="item.id === question.id">
                        <el-checkbox class="checkbox" v-model="item.isChecked" @change="handleCheckQuestion($event, question.id)"></el-checkbox>
                      </template>
                      <div style="width: 100%">
                        <!--题目卡片头部-->
                        <div class="header">
                          <div class="question-type">{{ ytConstant.questionType.getLabel(question.questionType)[0] }}</div>
                          <p class="audit">
                            <YTIcon :href="auditIcons[question.status]"></YTIcon>
                          </p>
                        </div>
                        <div class="body">
                          <!--题干-->
                          <div class="stem">
                            {{ index + 1 }}.&nbsp;
                            <div v-html="question.stem"></div>
                          </div>
                          <component :is="questionComponents[question.questionType]" :question="question" style="margin-top: 16px"></component>
                          <div class="correct-answer">
                            <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
                            <div v-html="question.questionAnswer"></div>
                          </div>
                          <div style="display: flex">
                            <div style="flex-shrink: 0">
                              <span style="letter-spacing: 12px">解</span>
                              <span style="letter-spacing: 5px">析:</span>
                            </div>
                            <div v-html="question.analysis"></div>
                          </div>
                          <div class="tag-and-knowledge" ref="tagAndKnowledge">
                            <div class="tk-item tk-select-item">
                              <span style="letter-spacing: 12px">标</span>
                              <span style="letter-spacing: 5px">签:</span>
                              <template v-for="(tag, tIndex) in question.tags">
                                <div :key="tIndex">
                                  <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">
                                    <div class="tk-point" :key="tIndex" v-if="tIndex < showSize">
                                      {{ tag.name }}
                                    </div>
                                  </el-tooltip>
                                </div>
                              </template>
                              <el-popover
                                placement="top"
                                width="202"
                                trigger="hover"
                                title="题目标签"
                                v-if="question.hasOwnProperty('tags') && showSize < question.tags.length"
                              >
                                <div class="tk-select-item tab-list" style="margin-bottom: 5px">
                                  <template v-for="(tagPop, tPopIndex) in question.tags">
                                    <div :key="tPopIndex">
                                      <el-tooltip
                                        effect="dark"
                                        :disabled="tagPop.name.length < 4"
                                        :content="tagPop.name"
                                        placement="top"
                                        :open-delay="1000"
                                      >
                                        <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= showSize" style="margin-bottom: 5px">
                                          {{ tagPop.name }}
                                        </div>
                                      </el-tooltip>
                                    </div>
                                  </template>
                                </div>
                                <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
                              </el-popover>
                            </div>
                          </div>
                        </div>
                        <!--题目卡片底部-->
                        <div class="footer">
                          <div>
                            <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.difficulty / 10 }}</p>
                            <p slot="reference" class="popover-reference">
                              使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount }}
                            </p>
                            <p slot="reference" class="popover-reference" style="display: flex;flex-flow: row">
                              设置分<span style="letter-spacing: 5px">数:</span>
                              <el-input
                                maxLength="3"
                                v-model="question.score"
                                oninput="value=value.replace(/[^\d.]/g,'')"
                                onkeyup="let v=this.value||'';if(v<1||v===''){this.value=0.5;}else if(v>1&&v.toString().indexOf('.')!==-1){this.value=Math.floor(v);} "
                                @input="setSmallTopicPoint(question.id, question.score, question.questionType)"
                              ></el-input>
                              <span style="margin-left: 6px">分</span>
                            </p>
                          </div>
                          <div @click="toDelete(question)" v-if="isRandom" style="cursor: pointer;" :loading="deleteLoading">
                            <span style="right: 5px">
                              <YTIcon @click="toDelete(question)" :href="'#icon-yichu'" :loading="deleteLoading"></YTIcon>
                              移除
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="empty"
                :style="{
                  height: 'calc(100vh - 303px)'
                }"
                v-if="this.questionAllid.length < 1"
              >
                <template v-if="!questionListLoading" :style="height">
                  <YTIcon :href="'#icon-zanwushuju'"></YTIcon>
                </template>
              </div>
            </template>
            <template v-else>
              <template v-if="questionLists.length > 0">
                <div v-for="(question, index) in questionLists" :key="index" class="item">
                  <div style="width: 100%">
                    <!--题目卡片头部-->
                    <div class="header">
                      <div class="question-type">{{ ytConstant.questionType.getLabel(question.questionType)[0] }}</div>
                      <p class="audit">
                        <YTIcon :href="auditIcons[question.status]"></YTIcon>
                      </p>
                    </div>
                    <div class="body">
                      <!--题干-->
                      <div class="stem">
                        {{ index + 1 }}.&nbsp;
                        <div v-html="question.stem"></div>
                      </div>
                      <component :is="questionComponents[question.questionType]" :question="question" style="margin-top: 16px"></component>
                      <div class="correct-answer">
                        <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
                        <div v-html="question.questionAnswer"></div>
                      </div>
                      <div style="display: flex">
                        <div style="flex-shrink: 0">
                          <span style="letter-spacing: 12px">解</span>
                          <span style="letter-spacing: 5px">析:</span>
                        </div>
                        <div v-html="question.analysis"></div>
                      </div>
                      <div class="tag-and-knowledge" ref="tagAndKnowledge">
                        <div class="tk-item tk-select-item">
                          <span style="letter-spacing: 12px">标</span>
                          <span style="letter-spacing: 5px">签:</span>
                          <template v-for="(tag, tIndex) in question.tags">
                            <div :key="tIndex">
                              <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">
                                <div class="tk-point" :key="tIndex" v-if="tIndex < showSize">
                                  {{ tag.name }}
                                </div>
                              </el-tooltip>
                            </div>
                          </template>
                          <el-popover
                            placement="top"
                            width="202"
                            trigger="hover"
                            title="题目标签"
                            v-if="question.hasOwnProperty('tags') && showSize < question.tags.length"
                          >
                            <div class="tk-select-item tab-list" style="margin-bottom: 5px">
                              <template v-for="(tagPop, tPopIndex) in question.tags">
                                <div :key="tPopIndex">
                                  <el-tooltip
                                    effect="dark"
                                    :disabled="tagPop.name.length < 4"
                                    :content="tagPop.name"
                                    placement="top"
                                    :open-delay="1000"
                                  >
                                    <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= showSize" style="margin-bottom: 5px">
                                      {{ tagPop.name }}
                                    </div>
                                  </el-tooltip>
                                </div>
                              </template>
                            </div>
                            <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
                          </el-popover>
                        </div>
                      </div>
                    </div>
                    <!--题目卡片底部-->
                    <div class="footer">
                      <div>
                        <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.difficulty / 10 }}</p>
                        <p slot="reference" class="popover-reference">使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount }}</p>
                      </div>
                      <div @click="toAdd(question)" v-if="isRandom" style="cursor: pointer;" :loading="addLoading">
                        <span style="right: 5px">
                          <i class="el-icon-circle-plus-outline"></i>
                          增加
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="empty"
                :style="{
                  height: isPackUp ? 'calc(100vh - 449px)' : 'calc(100vh - 348px)'
                }"
                v-if="this.questionLists.length < 1"
              >
                <template v-if="!questionListLoading" :style="height">
                  <YTIcon :href="'#icon-zanwushuju'"></YTIcon>
                </template>
              </div>
            </template>
            <div class="page" v-if="filters.selection.radio === '2'">
              <div>
                <Page
                  class="yt-page"
                  :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
                  :total="total"
                  :current="pageNum + 1"
                  :page-size-opts="[5, 10, 20, 50]"
                  :page-size="pageSize"
                  show-elevator
                  show-sizer
                  show-total
                  @on-change="changePage"
                  @on-page-size-change="changePageSize"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--右侧导航展开-->
      <div class="check-right">
        <div :class="{ 'right-drawer': drawer }" v-if="drawer && filters.selection.radio === '1'">
          <YTIcon :href="'#icon-shouqi'" @click="drawer = false" />
          <div class="header">
            <p>
              已选试题<span style=" color: #448BFF;margin: 0 5px">{{ questionAllid.length }}</span
              >题
            </p>
          </div>
          <div class="content">
            <div class="content-list">
              <template>
                <div v-for="(item, index) in questionList" v-if="item.questionCount !== 0">
                  <div class="content-info">
                    <div class="type"></div>
                    <p>{{ item.name }}</p>
                    <el-input
                      maxLength="3"
                      oninput="value=value.replace(/[^\d.]/g,'')"
                      style="min-width: 40px;margin-right: 5px"
                      v-model="item.totalScore"
                      @keydown.enter.native="setBigTopicPoint(item.totalScore, index)"
                    ></el-input>
                    <p>分</p>
                  </div>
                  <div class="grid-container">
                    <div
                      class="square"
                      v-for="(question, index) in questionList[index].questionScores"
                      :style="{ marginRight: (index + 1) % 5 === 0 ? '0' : '10px' }"
                      :key="index"
                      :class="{
                        'preview-question-item': true,
                        current: questionId === question.id
                      }"
                      @click="handleClickType(question.id)"
                    >
                      {{ index + 1 }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@/components/common/YTIcon'
import TrueOrFalse from '../../question/list/questions/TrueOrFalse'
import Choice from '../../question/list/questions/Choice'
import Code from '../../question/list/questions/Code'
import ability from '@/api/ability'
import question from '@/api/question'
import paper from '@/api/paper'
import questionApi from '@api/question'
import tagApi from '@api/tag'
export default {
  name: 'PaperEdit',
  components: {
    YTIcon,
    TrueOrFalse,
    Choice,
    Code
  },
  data() {
    return {
      defaultShowNodes: [],
      selectedQuestionIds: [],
      addLoading: false,
      deleteLoading: false,
      saveLoading: false,
      paperInfo: [],
      drawer: false,
      questionId: '',
      radioChange: '1',
      paperId: this.$route.query.paperId,
      abilityId: '',
      addQuestionIds: [],
      removeQuestionIds: [],
      papernew: {
        paperName: '',
        questionCount: 0,
        totalPoints: 0,
        paperBankId: '',
        questionSimpleVOS: []
      },
      pageNum: 0,
      pageSize: 10,
      total: 0,
      questionAll: [], //已选用的全部题目
      questionAllid: [], //已选用的全部题目id
      questionSelection: [], //被选中题目
      isSelectAll: false, //是否全选
      isIndeterminate: false, //是否开启不确定全选
      knowledgePointDownKeyword: '', //知识点筛选搜索
      knowledgePointsDown: [], //知识点筛选树
      questionPaperInfo: {}, //悬停显示使用了题目的试卷列表
      questionListLoading: false, //是否加载中
      showSize: 0, //根据屏幕宽度展示标签的个数
      auditIcons: ['#icon-yishenhe', '#icon-caogao', '#icon-weishenhe'], //审核图标
      questionComponents: [TrueOrFalse, Choice, Choice, null, null, Code], //题目组件
      questionList: [], //已选用的题目列表
      questionLists: [], //未选用的题目列表
      difficulties: [
        { label: '简单', value: '3' },
        { label: '中等', value: '5' },
        { label: '困难', value: '7' }
      ],
      knowledgePointProps: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      isPackUp: true,
      isPackUps: false,
      keyword: '',
      keywords: '',
      treeData: [],
      filters: {
        selection: {
          label: '选用',
          radio: '1'
        },
        questionType: {
          label: '题型',
          children: [{ label: '全部', value: null }, ...this.ytConstant.questionType.getMenu()]
        },
        questionStatus: {
          label: '状态',
          children: [
            { label: '全部', value: null },
            { label: '已审核', value: '0' },
            { label: '未审核', value: '2' },
            { label: '草稿', value: '1' }
          ]
        },
        other: {
          label: '其他',
          children: [
            {
              label: '使用次数',
              property: 'useCountDesc',
              type: 'sort',
              function: this.sortByUseCount
            },
            {
              label: '难度筛选',
              property: 'difficultyType',
              type: 'singleSelect',
              function: this.selectDifficulty,
              options: [
                { label: '简单', value: '3' },
                { label: '中等', value: '5' },
                { label: '困难', value: '7' }
              ]
            },
            {
              label: '标签筛选',
              property: 'questionTagIds',
              type: 'multipleSelect',
              function: this.selectQuestionTag,
              options: []
            },
            { label: '知识点筛选', property: 'knowledgePointIds', type: 'tree' }
          ]
        }
      },
      searchQuestionForm: {
        questionName: '',
        questionBankId: '',
        tagIds: [],
        searchForm: {
          questionType: null, //题型
          questionStatus: null, //状态
          difficultyType: null, //难度
          useCountDesc: '', //使用次数
          questionTagIds: [], //标签
          knowledgePointIds: [], //知识点
          haveKnowledge: true,
          hasKnowledge: false,
          hasTestCase: false
        }
      },
      sectionForms: [],
      isRandom: true
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
    this.calculateSize(document.body.clientWidth)
    this.getPaperBrief()
    this.getPaperQuestiontree()
    this.getKnowledgeTree()
    this.getTags()
    if (this.$route.query.isRandom) {
      this.isRandom = false
    }
  },
  watch: {
    screenWidth(val) {
      this.calculateSize(val)
    }
  },
  methods: {
    //试卷总分统计
    statisticalScore() {
      let total = 0
      this.papernew.questionSimpleVOS.forEach(item => {
        total += parseFloat(item.score == 0 ? 0.5 : item.score)
      })
      this.papernew.totalPoints = total
    },
    //获取标签
    getTags() {
      let payload = {
        keyword: '',
        typeId: this.ytConstant.tagType.QUESTION
      }
      tagApi.searchTagByTypeId(payload).then(res => {
        if (res.code === 0) {
          let tags = res.res.map(item => {
            return {
              label: item.name,
              value: item.tagId
            }
          })
          this.filters.other.children[2].options = tags
          this.$forceUpdate()
        }
      })
    },
    getKnowledgeTree() {
      //获取知识点树
      questionApi.getKnownLedgeTree().then(res => {
        if (res.code === 0) {
          this.knowledgePointsDown = res.res
        }
      })
    },
    setCurrentKey(id) {
      this.$nextTick(() => {
        this.$refs['abilty-tree'].setCurrentKey(id ? id : this.treeData[0].id)
      })
    },
    //设置大题分数
    setBigTopicPoint(item, index) {
      let total = 0
      let point = Math.floor(item / this.questionList[index].questionCount) === 0 ? 0.5 : Math.floor(item / this.questionList[index].questionCount)
      this.questionList[index].questionScores.forEach(q => {
        q.score = point
        total = total + point
        this.papernew.questionSimpleVOS.forEach(s => {
          if (s.questionId === q.id) {
            s.score = point
          }
        })
      })
      this.questionList[index].totalScore = total
      this.$forceUpdate()
      this.statisticalScore()
    },
    //设置小题分数
    setSmallTopicPoint(questionId, score, questionType) {
      let total = 0
      if (score < 1 || score === '') {
        score = 0.5
      } else if (score > 1 && score.toString().indexOf('.') !== -1) {
        score = Math.floor(score)
      }
      this.questionList.forEach((q, index) => {
        if (this.ytConstant.questionType.getLabel(questionType) === q.name) {
          this.questionList[index].questionScores.forEach(s => {
            let v = s.score
            if (v < 1 || v === '') {
              v = 0.5
            } else if (v > 1 && v.toString().indexOf('.') !== -1) {
              v = Math.floor(v)
            }
            total = total + parseFloat(v)
          })
          this.questionList[index].totalScore = total
        }
        this.$forceUpdate()
      })
      this.papernew.questionSimpleVOS.forEach(item => {
        if (item.questionId === questionId) {
          item.score = score
        }
      })
      this.statisticalScore()
    },
    handleClickType(type) {
      this.questionId = type
      this.$el.querySelector('#anchor-' + type).scrollIntoView({
        behavior: 'smooth' // 平滑过渡
      })
    },
    //增加
    toAdd(data) {
      this.addLoading = true
      let arr = true
      this.papernew.questionSimpleVOS.forEach(item => {
        if (item.questionId === data.id) {
          arr = false
        }
      })
      if (arr) {
        this.papernew.questionSimpleVOS.push({
          questionId: data.id,
          score: 1,
          isChecked: false
        })
        this.$message.success('添加成功')
      }
      this.getPaperQuestiontree()
      this.searchPaperSlectedQuestion()
      this.statisticalScore()
      this.addLoading = false
    },
    //移除
    toDelete(data) {
      this.deleteLoading = true
      this.papernew.questionSimpleVOS.forEach((item, index) => {
        if (item.questionId === data.id) {
          this.papernew.questionSimpleVOS.splice(index, 1)
        }
      })
      this.$message.success('移除成功')
      this.getPaperQuestiontree()
      this.searchPaperSlectedQuestion()
      this.statisticalScore()
      this.deleteLoading = false
    },
    //批量移除题目
    movePaper() {
      if (this.questionSelection.length > 0) {
        this.questionSelection.forEach(item => {
          this.papernew.questionSimpleVOS.forEach((s, index) => {
            if (s.questionId === item) {
              this.papernew.questionSimpleVOS.splice(index, 1)
            }
          })
        })
        this.$message.success('批量移除成功')
        this.questionSelection = []
        this.getPaperQuestiontree()
        this.searchPaperSlectedQuestion()
      } else {
        this.$message.error('请选择要移除题目')
      }
      this.statisticalScore()
    },
    agreeChange(value) {
      this.radioChange = value
      if (value === '2') {
        this.searchPaperNotSelectedQuestion()
      }
    },
    calculateSize(width) {
      if (width <= 1440) {
        this.showSize = 10
      } else if (width > 1440 && width < 1920) {
        this.showSize = 15
      } else {
        this.showSize = 16
      }
    },
    //左侧技能树
    getPaperQuestiontree() {
      this.getSelectedQuestionIds()
      let payload = {
        keyword: this.keywords,
        paperId: this.paperId,
        selectedQuestionIds: this.selectedQuestionIds
      }
      ability.getPaperQuestionCount(payload).then(res => {
        if (res.code === 0) {
          this.treeData = res.res
          this.$nextTick(() => {
            this.$refs['abilty-tree'].setCurrentKey(this.abilityId)
          })
          this.defaultShowNodes.push(this.abilityId)
          this.searchPaperSlectedQuestion()
          if (this.radioChange === '2') {
            this.searchPaperNotSelectedQuestion()
          }
        }
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchPaperNotSelectedQuestion()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchPaperNotSelectedQuestion()
    },
    //全选
    handleCheckAllChange(val) {
      if (val) {
        this.questionSelection = this.questionAll.map(item => {
          item.isChecked = true
          return item.id
        })
      } else {
        this.questionAll.map(item => {
          item.isChecked = false
        })
        this.questionSelection = []
      }
      this.isIndeterminate = false
      this.$forceUpdate()
    },
    //单选
    handleCheckQuestion(value, questionId) {
      if (Array.isArray(value) && value.includes(undefined)) {
        return
      }
      if (value) {
        this.questionSelection.push(questionId)
      } else {
        this.questionSelection.splice(this.questionSelection.indexOf(questionId), 1)
      }
      let checkedCount = this.questionSelection.length
      this.isSelectAll = checkedCount === this.questionAll.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.questionAll.length
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    toFilterNodes(ref) {
      this.$refs[ref].filter(this.keywords)
      this.$refs[ref].setCheckedNodes([])
    },
    //保存试卷
    savePaper() {
      this.saveLoading = true
      this.sectionForms = []
      this.papernew.questionSimpleVOS.forEach(item => {
        this.sectionForms.push({
          points: item.score == 0 ? 0.5 : item.score,
          questionId: item.questionId
        })
      })
      let payload = {
        paperName: this.papernew.paperName,
        paperId: this.paperId,
        questionPoints: this.sectionForms
      }
      paper
        .savePaper(payload)
        .then(res => {
          if (res.code === 0) {
            this.paperId = res.res
            this.sectionForms = []
            this.$message.success('保存试卷成功')
          }
        })
        .finally(() => {
          this.saveLoading = false
        })
    },
    //返回试卷
    goBack() {
      if (this.$route.query.isRandom) {
        this.$router.push({
          path: `/manage/paper/list/${this.$route.query.paperBankId}`
        })
      } else {
        this.$router.push({
          path: '/manage/paper/detail',
          query: {
            id: this.paperId
          }
        })
      }
    },
    //左侧树技能列表点击事件
    handleClickTreeNode(data, node) {
      this.isSelectAll = false
      this.questionAll = []
      this.questionAllid = []
      this.abilityId = data.id
      this.searchPaperSlectedQuestion()
      if (this.radioChange === '2') {
        this.searchPaperNotSelectedQuestion()
      }
    },
    //获取当前技能下所有已选题目的ID
    getSelectedQuestions() {
      this.questionAll = []
      this.questionAllid = []
      this.questionList.some(item => {
        item['questionScores'].map(m => {
          this.questionAll.push({ id: m.id, isChecked: false })
          this.questionAllid.push(m.id)
        })
      })
    },
    //获取试卷选中题目id
    getSelectedQuestionIds() {
      this.selectedQuestionIds = []
      this.papernew.questionSimpleVOS.forEach(item => {
        this.selectedQuestionIds.push(item.questionId)
      })
    },
    //根据条件查询试卷已选题目
    searchPaperSlectedQuestion() {
      this.questionListLoading = true
      this.getSelectedQuestionIds()
      let payload = {
        abilityId: this.abilityId,
        keyword: this.keyword,
        paperId: this.paperId,
        selectedQuestionIds: this.selectedQuestionIds
      }
      question
        .searchPaperSelectedQuestion(payload)
        .then(res => {
          if (res.code === 0) {
            this.questionList = res.res.sections.map(item => {
              if (item.name === '判断题') {
                item.questionScores = item.questionScores.map(q => {
                  q.answer = q.questionAnswer === '正确'
                  return q
                })
              }
              item.questionScores = item.questionScores.map(t => {
                this.papernew.questionSimpleVOS.forEach(s => {
                  if (t.id === s.questionId) {
                    t.score = s.score
                  }
                })
                return t
              })
              item.totalScore = 0
              item.questionScores.forEach(t => {
                item.totalScore += parseFloat(t.score)
              })
              return item
            })
            this.getSelectedQuestions()
            this.questionListLoading = false
          } else {
            this.questionListLoading = false
          }
        })
        .catch(() => {
          this.questionListLoading = false
        })
    },
    //根据条件分页查询试卷未选题目
    searchPaperNotSelectedQuestion() {
      this.getSelectedQuestionIds()
      this.questionListLoading = true
      let hasKnowledges = ''
      if (this.searchQuestionForm.searchForm.hasKnowledge && !this.searchQuestionForm.searchForm.haveKnowledge) {
        hasKnowledges = false
      } else if (!this.searchQuestionForm.searchForm.hasKnowledge && this.searchQuestionForm.searchForm.haveKnowledge) {
        hasKnowledges = true
      }
      let payload = {
        abilityId: this.abilityId,
        difficultyType: this.searchQuestionForm.searchForm.difficultyType,
        hasKnowledge: hasKnowledges,
        keyword: this.keyword,
        knowledgePointIds: this.searchQuestionForm.searchForm.knowledgePointIds,
        questionStatus: this.searchQuestionForm.searchForm.questionStatus,
        questionTagIds: this.searchQuestionForm.searchForm.questionTagIds,
        questionType: this.searchQuestionForm.searchForm.questionType,
        selectedQuestions: this.selectedQuestionIds,
        useCountDesc: this.searchQuestionForm.searchForm.useCountDesc
      }
      question
        .searchPaperNotSlectedQuestion(this.pageNum, this.pageSize, payload)
        .then(res => {
          if (res.code === 0) {
            this.questionLists = res.res.data
            this.total = res.res.total
            this.questionListLoading = false
          } else {
            this.questionListLoading = false
          }
        })
        .catch(() => {
          this.questionListLoading = false
        })
    },
    //获取试卷信息
    getPaperBrief() {
      paper.getPaperBrief(this.paperId).then(res => {
        if (res.code === 0) {
          this.papernew = res.res
          let questionSimple = []
          this.papernew.questionSimpleVOS.forEach(item => {
            questionSimple.push({ ...item, isChecked: false })
          })
          this.papernew.questionSimpleVOS = questionSimple
        }
      })
    },
    handleClickFilter(key, value) {
      this.searchQuestionForm.searchForm[key] = value
      this.reSearchQuestions()
    },
    sortByUseCount() {
      if (this.searchQuestionForm.searchForm.useCountDesc === '') {
        this.searchQuestionForm.searchForm.useCountDesc = true
      } else if (this.searchQuestionForm.searchForm.useCountDesc) {
        this.searchQuestionForm.searchForm.useCountDesc = false
      } else {
        this.searchQuestionForm.searchForm.useCountDesc = ''
      }
      this.reSearchQuestions()
    },
    selectDifficulty(command) {
      this.searchQuestionForm.searchForm.difficultyType = command
      this.reSearchQuestions()
    },
    selectQuestionTag(command) {
      let tagIndex = this.searchQuestionForm.searchForm.questionTagIds.indexOf(command)
      if (tagIndex !== -1) {
        this.searchQuestionForm.searchForm.questionTagIds.splice(tagIndex, 1)
      } else {
        this.searchQuestionForm.searchForm.questionTagIds.push(command)
      }
      this.reSearchQuestions()
    },
    reSearchQuestions() {
      this.pageNum = 0
      this.total = 0
      this.questionAll = []
      this.questionAllid = []
      this.searchPaperNotSelectedQuestion()
    },
    toFilterNode(ref) {
      //知识点树过滤点击触发(左侧)
      this.$refs[ref].filter(this.knowledgePointDownKeyword)
      this.$refs[ref].setCheckedNodes([])
    },
    toFilterNodeDown(ref, keyword) {
      //知识点树过滤点击触发(筛选)
      this.$refs[ref][0].filter(keyword)
      this.$refs[ref][0].setCheckedNodes([])
    },
    toFilterNodeUpdate(ref, keyword, index) {
      //知识点树过滤点击触发(题目卡片)
      this.$refs[ref][index].filter(keyword)
      this.$refs[ref][index].setCheckedKeys(
        ref === 'knowledge-tree-update' ? this.questionList[index].knowledgePointIds : this.questionList[index].tagIds
      )
    },
    resetCheckedNodesDown(ref) {
      //知识点树选择重置
      this.$refs[ref][0].setCheckedKeys([])
    },
    toSearchByKnowledge() {
      this.searchQuestionForm.searchForm.knowledgePointIds = this.$refs['knowledge-tree-down'][0].getCheckedKeys()
      this.reSearchQuestions()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables';
@import '~@/theme/filterDropdownMenu';
.yt-main {
  height: 100%;
  text-align: left;
  padding-top: 0;
}
.yt-header-toolbar {
  ::v-deep .el-loading-spinner {
    margin-top: -14px;
  }
}
.yt-container {
  height: calc(100% - 48px);
}
.yt-content {
  background-color: #f3f4f7;
  padding: 0;
  box-shadow: none;
}
.yt-content-tree {
  width: 280px;
  box-shadow: none;
  .search {
    margin: 12px 0;
    padding: 0 10px;
    height: 32px;
  }
  ::v-deep .el-input__suffix {
    right: 15px;
  }
}
.yt-content-top {
  padding: 10px 20px;
  margin-bottom: 10px;
  height: 75px;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  .question-order {
    height: 32px;
    background: #ffffff;
    opacity: 1;
    border-radius: 4px;
    ::v-deep .el-input__inner {
      height: 32px;
      color: #000000;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 22px;
    }
  }
  .question-news {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    span {
      margin-right: 40px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
      opacity: 1;
    }
  }
}
//筛选列表
.filter-container {
  background: #ffffff;
  padding: 16px 20px 0;
  margin-bottom: 10px;
  position: relative;
  .input-container {
    position: absolute;
    width: 334px;
    right: 125px;
    top: 7px;
    ::v-deep .el-input__inner {
      height: 38px;
    }
  }
  .pack-up {
    position: absolute;
    width: 53px !important;
    height: 25px;
    line-height: 25px;
    background: #f7f7f7;
    border-radius: 13px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    right: 25px;
    top: 13px;
    .flexStyle(flex, flex-start, center);
    p {
      margin-left: 8px;
      font-size: @small;
      width: 24px !important;
      user-select: none; //不被选中
    }

    svg {
      width: 9px;
      height: 14px;
      margin-left: 4px;
      margin-top: 14px;
      margin-right: 6px;
      transition: all 0.2s ease-in-out;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .active {
      -webkit-transform: translateY(-50%) rotate(180deg);
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .row {
    .flexStyle(flex, flex-start);
    height: 20px;
    margin-bottom: 16px;
    color: #000000;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      font-size: @small;
      margin-right: 20px;
      height: 20px;
      line-height: 20px;
    }
    .item {
      height: 20px;
      line-height: 20px;
      margin: 0 8px;
      padding: 0 15px;
      font-size: @small;
      cursor: pointer;
    }
    .default-item {
      &:hover {
        border-radius: 11px;
        background: #448bff;
        color: #ffffff;
      }
    }
    .active {
      border-radius: 11px;
      background: #448bff;
      color: #ffffff;
    }
    .checkbox-container {
      .flexStyle(flex, flex-start, center);
      border-left: 1px solid #d8d8d8;
      margin-left: 17px;
      .el-checkbox {
        margin-left: 40px;
        margin-right: 0;
        ::v-deep .el-checkbox__label {
          font-size: @small;
          padding-left: 6px;
          color: #000000 !important;
        }
      }
    }
    .other-item {
      padding: 0;
      margin: 0 22px 0 8px;
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      user-select: none; //禁止双击选中文字
      svg {
        color: #a6a6a6;
      }
    }
    .asc {
      transform: rotate(180deg);
    }
    .select {
      font-size: @small;
      &:hover {
        color: #448bff;
        i {
          transform: rotate(180deg);
        }
      }
    }
    .el-dropdown {
      .flexStyle(flex, flex-start, center);
      height: 20px;
      line-height: 20px;
      color: #000000;
    }
    .el-icon-arrow-down {
      font-size: @small;
      color: #999999 !important;
      transition: all 0.2s ease-in-out;
    }
  }
}
//全选
.select-all {
  .flexStyle(flex, flex-start, center);
  background: #ffffff;
  height: 36px;
  border-bottom: 1px solid rgba(226, 228, 232, 0.3);
  padding-left: 20px;
  .el-checkbox {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    ::v-deep .el-checkbox__label {
      font-size: @small;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}
//题目列表
.question-container {
  overflow-y: auto;
  .list {
    background-color: #f8f8f8;
  }
  .item {
    display: flex;
    background-color: #ffffff;
    margin-bottom: 9px;
    padding: 0 0 0 20px;
    .checkbox {
      height: 16px;
      margin-top: 19px;
      margin-right: 4px;
      ::v-deep .el-checkbox__label {
        display: none;
      }
    }
    .header {
      .flexStyle(flex, flex-start);
      margin: 16px 0 6px;
      .question-type {
        width: 23px;
        height: 23px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        background: #448bff;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 16px;
        font-size: 14px;
      }
      .audit {
        .flexStyle(flex, center, center);
        height: 23px;
        overflow: hidden;
      }
      svg {
        font-size: 68px;
      }
    }
    //题目部分
    .body {
      font-size: @small;
      //overflow: hidden;
      width: calc(100% - 259px) !important;
      .stem {
        display: flex;
      }
      .stem-code {
        height: 200px;
        margin-right: 40px;
        margin-top: 15px;
      }
      .correct-answer {
        display: flex;
        color: #06c281;
        margin: 16px 0;
      }
      .tag-and-knowledge {
        .flexStyle(flex, flex-start, center);
        margin: 14px 0 0;
        padding-bottom: 15px;
        border-bottom: 1px dotted #e9e9e9;
        height: 20px;
        .tk-item {
          .flexStyle(flex, flex-start, center);
          width: calc(100% / 2);
        }
      }
    }
    .footer {
      .flexStyle(flex, space-between, center);
      height: 20px;
      margin: 10px 0;
      font-size: @small;
      padding-right: 30px;
      div {
        .flexStyle(flex, flex-start, center);
      }
      .el-input {
        max-width: 60px;
        ::v-deep .el-input__inner {
          height: 20px;
          /*width: 15px;*/
          border: 1px solid #d9d9d9;
          text-align: center;
        }
      }
      p {
        height: 18px;
        line-height: 20px;
        border-right: 1px solid #d8d8d8;
        padding-right: 18px;
        margin-right: 16px;
        cursor: pointer;
        &:last-child {
          height: 18px;
          border-right: 0px solid #d8d8d8;
          line-height: 20px;
          padding-right: 18px;
          margin-right: 16px;
          cursor: pointer;
        }
      }
      .inactive {
        cursor: initial;
        &:hover {
          color: #000;
        }
      }
      svg {
        color: #999999;
        &:hover {
          color: #448bff;
        }
      }
      .button {
        color: #666666;
        margin-left: 17px;
        cursor: pointer;
        &:hover {
          color: #448bff;
          svg {
            color: #448bff;
          }
        }
        svg {
          font-size: 14px;
          margin-right: 6px;
        }
      }
    }
  }
  .page {
    .flexStyle(flex, center, center);
    height: 78px;
    background-color: #ffffff;
    div {
      width: 100%;
      height: 25px;
      padding: 0 30px;
    }
    .yt-page {
      margin-top: 0;
    }
  }
}
.fontSize {
  font-size: @small;
}
.fontColor {
  color: #448bff;
}
.empty {
  .flexStyle(flex, center, center);
  background-color: #ffffff;
  svg {
    font-size: 100px;
  }
}
.question-sort {
  width: 100%;
  height: 50px;
  background: #f0f0f0;
  opacity: 1;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  padding: 15px 0px 15px 20px;
  p {
    font-size: 14px;
    line-height: 22px;
    color: #282c3d;
    opacity: 1;
    margin-right: 5px;
  }
  .el-input {
    width: 70px;
    ::v-deep .el-input__inner {
      height: 24px;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      text-align: center;
    }
  }
}
.fixed-icon {
  position: fixed;
  top: 35%;
  right: 2%;
  width: 40px;
  height: 40px;
  background: #e4e4e4;
  border-radius: 50%;
  opacity: 1;
  &:hover {
    background: #b5b5b5;
  }
}
.fixed-icon-num {
  position: fixed;
  top: 35%;
  right: 2%;
  width: 16px;
  height: 16px;
  background: #ff5050;
  border-radius: 50%;
  opacity: 1;
  font-size: 8px;
  text-align: center;
  color: #ffffff;
  margin-right: -2px;
  margin-top: -2px;
}
.fixed-icon-wenjian {
  fill: #ffffff;
  position: fixed;
  top: 35%;
  right: 2%;
  width: 21px;
  height: 18px;
  margin-right: 8px;
  margin-top: 11px;
}
//drawer
.right-drawer {
  width: 259px;
  height: calc(100% - 70px);
  float: right;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  opacity: 1;
  border-radius: 6px 0px 0px 6px;

  svg {
    transform: rotate(180deg);
    margin-top: 130%;
    float: left;
    margin-left: 7px;
  }

  .header {
    padding-left: 20px;
    padding-top: 18px;
    height: 50px;
    border-bottom: 1px solid #e2e4e8;

    p {
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      color: #282c3d;
    }
  }

  .content {
    padding: 0 14px;
    overflow-y: auto;
    height: calc(100% - 98px);

    .type {
      width: 3px;
      height: 10px;
      background: #448bff;
      opacity: 1;
      border-radius: 2px;
      margin-top: 8px;
      margin-right: 5px;
    }

    .content-info {
      display: flex;
      flex-flow: nowrap;
      margin-top: 12px;
      .el-input {
        width: 70px;
        ::v-deep .el-input__inner {
          height: 24px;
          border: 1px solid #d9d9d9;
          border-radius: 3px;
          text-align: center;
        }
      }
    }

    p {
      font-size: 12px;
      color: #282c3d;
      opacity: 1;
      margin-top: 4px;
      margin-right: 5px;
    }

    .grid-container {
      display: flex;
      flex-wrap: wrap;
      border-bottom: solid 1px #e3e3e3;
      padding-top: 12px;

      .square {
        width: 24px;
        height: 24px;
        margin-bottom: 10px;
        border-radius: 3px;
        border: 1px solid #d9d9d9;
        text-align: center;
      }

      .preview-question-item {
        width: 32px;
        height: 32px;
        border: solid 1px #e3e3e3;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        cursor: pointer;

        &.current {
          color: #fff;
          background: #568feb;
          border: solid 2px #568feb;
        }
      }
    }
  }
}
.el-dropdown-menu {
  max-height: calc(100vh - 380px);
}
.list-container {
  height: calc(100vh - 500px) !important;
}
//题库列表悬停标签
.tab-list {
  display: flex;
  flex-wrap: wrap;
}
.tk-point {
  max-width: 70px;
  height: 22px;
  line-height: 22px;
  border-color: #edf4ff;
  border-radius: 2px;
  color: #448bff;
  margin-right: 8px;
  padding: 0 8px;
  background-color: #edf4ff;
  font-size: @small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  i {
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
  }
  &:focus {
    border-color: #448bff;
  }
}
.check-right {
  height: calc(100% - 48px);
  background: #ffffff;
  position: fixed;
  right: 10px;
  z-index: 999;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px 0 0 6px;
}
::v-deep .yt-container-flex .yt-content {
  width: calc(100% - 10px - 280px);
}
::v-deep .yt-container-flex.is-pack-up .yt-content {
  width: calc(100% - 10px - 41px);
}
</style>
